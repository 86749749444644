@import "../../const/settings.scss";

.wpChatbox {
  .showChatbot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px;
    box-sizing: border-box;
    border-radius: 20px 20px 0px 0px;
    background: var(--Color-6, #29c0d3);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #ffffff;
    position: fixed;
    right: 0;
    width: 321px;
    height: 30px;
    z-index: 500;
    bottom: 0;
    cursor: pointer;
    div {
      flex: 1;
      color: #fff;
      text-align: center;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    svg {
      font-size: 22px;
      color: #1ecadfd9;
      background: #e8e8e8;
      border: none;
      border-radius: 30px;
    }
  }
  .showChatbotMobile {
    cursor: pointer;
    .floatButton {
      display: none;
      border-radius: 99px;
      border: 4px solid #00c5d7;
      background: white;
      height: 60px;
      width: 60px;
      position: fixed;
      bottom: 126px;
      right: 14px;
      z-index: 100;
      @include media(md) {
        display: block;
      }
      img {
        border-radius: 99px;
      }
      .arrowFloatButton {
        position: absolute;
        bottom: -2px;
        right: -2px;
      }
      .chatAlert {
        position: absolute;
        left: -4px;
        top: -4px;
      }
    }
  }
  @include media(md) {
    .showChatbot {
      display: none;
    }
    .showChatbotMobile {
      display: block;
    }
  }
}
.wpFormChatbox {
  .wpFormChat {
    background: #f5f5f5;
    position: fixed;
    bottom: 30px;
    right: 0;
    max-width: 321px;
    z-index: 999;
    color: rgb(0, 0, 0);
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    transform: scale(0);
    .chatHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      box-sizing: border-box;
      border-radius: 20px 20px 0px 0px;
      background: var(--Color-6, #29c0d3);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      color: #ffffff;
      position: relative;
      div {
        flex: 1;
        color: #fff;
        text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      img {
        cursor: pointer;
      }
    }
    .chatBody {
      overflow: hidden;
      height: 419px;
      overflow-y: scroll;
      padding: 13px;
      // -ms-overflow-style: none;  /* Ẩn thanh cuộn trên IE và Edge */
      // scrollbar-width: none;
      .chatContainer {
        display: flex;
        align-items: flex-end;
      }

      .chatContainer {
        display: flex;
        gap: 7px;
        align-items: flex-end;
        img {
          border-radius: 30px;
          background: white;
          object-fit: none;
          border: #29c0d3 1px solid;
        }
        .chatContent {
          border-radius: 15px;
          background: var(--Color-3, #fff);
          padding: 12px 10px;
          margin-bottom: 16px;
          color: #000;
          text-align: justify;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          &:last-child {
            margin-bottom: 0px;
          }
        }
        .ant-form-item {
          margin-bottom: 9px;
          &:last-child {
            margin-bottom: 0px;
          }
        }
        .ant-input {
          // border-radius: 15px;
          // background: #F5F5F5;
          // border: none;
          // height: 31px;
          border-radius: 8px;
        }
        .ant-form-item-explain-error {
          font-size: 13px;
          text-align: left;
        }
      }
      .chatBoxThreeDot {
        display: flex;
        margin-top: 8px;
        .stage {
          margin-left: 0px;
          margin-top: 0px;
          background: #ffffff;
          width: 56px;
          height: 36px;
          border-radius: 99px;
        }
        .dot-falling {
          position: relative;
          top: 15px;
          left: -9975px;
          width: 6px;
          height: 6px;
          border-radius: 8px;
          background-color: #dbeffa;
          color: #dbeffa;
          box-shadow: 9999px 0 0 0 #dbeffa;
          animation: dot-falling 1s infinite linear;
          animation-delay: 0.1s;
        }
        .dot-falling::before,
        .dot-falling::after {
          content: "";
          display: inline-block;
          position: absolute;
          top: 0;
        }
        .dot-falling::before {
          left: 4px;
          width: 6px;
          height: 6px;
          border-radius: 8px;
          background-color: #dbeffa;
          color: #dbeffa;
          animation: dot-falling-before 1s infinite linear;
          animation-delay: 0s;
        }
        .dot-falling::after {
          right: 4px;
          width: 6px;
          height: 6px;
          border-radius: 8px;
          background-color: #dbeffa;
          color: #dbeffa;
          animation: dot-falling-after 1s infinite linear;
          animation-delay: 0.2s;
        }
        @keyframes dot-falling {
          0% {
            box-shadow: 9999px -15px 0 0 rgba(152, 128, 255, 0);
          }
          25%,
          50%,
          75% {
            box-shadow: 9999px 0 0 0 #dbeffa;
          }
          100% {
            box-shadow: 9999px 15px 0 0 rgba(152, 128, 255, 0);
          }
        }
        @keyframes dot-falling-before {
          0% {
            box-shadow: 9984px -15px 0 0 rgba(152, 128, 255, 0);
          }
          25%,
          50%,
          75% {
            box-shadow: 9984px 0 0 0 #dbeffa;
          }
          100% {
            box-shadow: 9984px 15px 0 0 rgba(152, 128, 255, 0);
          }
        }
        @keyframes dot-falling-after {
          0% {
            box-shadow: 10014px -15px 0 0 rgba(152, 128, 255, 0);
          }
          25%,
          50%,
          75% {
            box-shadow: 10014px 0 0 0 #dbeffa;
          }
          100% {
            box-shadow: 10014px 15px 0 0 rgba(152, 128, 255, 0);
          }
        }
      }
    }
    .chatFooter {
      display: flex;
      justify-content: space-around;
      align-items: center;
      gap: 10px;
      padding: 10px;

      img {
        border-radius: 30px;
        border: #29c0d3 1px solid;
      }

      .wpBtnChatBox {
        width: 100%;
        border-radius: 16px;
        background: #fff;
        color: #2a3467;
        padding: 8px 12px;
        .btnChatBox {
          border-radius: 8px;
          background: #29c0d3;
          display: flex;
          color: white;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          border: none;
          width: 100%;
          margin-bottom: 0px !important;
          span {
            font-size: 14px;
          }
        }
        .disabledBtn {
          background-color: #d6d6d6;
          color: #a0a0a0;
        }
      }

      .chatBoxAvatar {
        position: unset !important;
        width: 40px !important;
        height: 40px !important;
        border-radius: 50%;
        object-fit: cover;
      }
      .imageAvatar {
        display: block;
        width: 50px;
        height: 40px;
        line-height: 39px;
        font-size: 20px;
      }
    }
  }
  .up {
    transform: scale(1);
    animation: upFormChat 0.4s ease-out forwards;
  }
  .down {
    animation: downFormChat 0.5s ease-out forwards;
  }
  @keyframes upFormChat {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes downFormChat {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(100vh);
      opacity: 0;
    }
  }
}
