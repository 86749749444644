@import "../../const/settings.scss";

.wp-phone-number{
   display: flex;
   justify-content: center;
    .ant-modal-content {
      background-image: url('../../../public/image/dialog-contact.png');
      background-size: cover; /* Hoặc 'contain' tùy thuộc vào nhu cầu của bạn */
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 25px;
      position: relative;
      .ant-modal-header{
         background: unset;
      }

      .ant-modal-body{
         width: 710px;
         height: auto;
         padding: 41px 0px 71px 0px;
         .wp-content{
            text-align: center;
            .logo-nhakhoahub{
               width: 218px;
               height: auto;
               margin-bottom: 14px;
            }
            .title{
               color: var(--Color-5, #293568);
               text-align: center;
               font-size: 20px;
               font-style: normal;
               font-weight: 600;
               line-height: 30px;
               margin-bottom: 35px;
            }
            .btn-phone-number{
               margin: 0 8px;
               border-radius: 30px;
               width: 224px;
               height: 54px;
               color: var(--Color-3, #FFF);
               font-size: 14px;
               font-style: normal;
               box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
               border: 1px solid #00C5D7;
               background: var(--Color-6, #29C0D3);
               span{
                  color: var(--Color-3, #FFF);
                  text-align: center;
                  font-size: 20px;
                  font-style: normal;
                  line-height: 30px;
               }
            }
         }  
      }
   }
}

@include media(md){
   .wp-phone-number {
      .ant-modal-content {
         .ant-modal-body {
            width: auto;
            padding: 20px 23px;
            .wp-content {
               .logo-nhakhoahub{
                  width: 145px;
                  height: auto;
               }
               .title{
                  font-size: 16px;
                  margin-bottom: 20px;
               }
               .btn-phone-number{
                  width: 190px;
                  height: 42px;
                  span{
                     font-size: 18px;
                  }
               }
            }
         }
      }
   }
}